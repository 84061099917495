$(function() {
  // ==================================================
  //  Function: スライダー
  // ==================================================
  $(".jsSlickSlider").slick({
    mobileFirst: false,
    dots: false,
    fade: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 2700,
    asNavFor: '.jsSlickSliderThumbnail',
  });
  $('.jsSlickSliderThumbnail').slick({
    slidesToShow: 5,
    arrows: false,
    mobileFirst: false,
    dots: false,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 2700,
    centerMode: true,
    asNavFor: '.jsSlickSlider',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '20px',
        },
      }
    ]
  });
  $('.jsSlickSliderThumbnail .slick-slide').on('click',function() {
    const index = $(this).attr('data-slick-index');
    $('.jsSlickSliderThumbnail').slick('slickGoTo', index, false);
  });
});